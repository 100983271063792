<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="5">
          <div class="title-header">
            Gestion des biens
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="loadingBien"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="6">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="errorBiens">
              <ul v-if="Array.isArray(errorBiens)" class="mb-0">
                <li v-for="(e, index) in errorBiens" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorBiens }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              v-if="checkPermission('GBHTCB')"
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                size="sm"
                class="block-icon mr-2"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GBHTELB')"
                @click.prevent.stop="exportFiles"
                :disabled="getShowNotifExportFile"
              >
                <font-awesome-icon
                  icon="cloud-download-alt"
                  :class="{ hidden: loadingExport }"
                />
                <v-progress-circular
                  v-if="loadingExport"
                  class="mr-2 mt-1"
                  indeterminate
                  width="2"
                  size="10"
                  color="#704ad1"
                ></v-progress-circular>
              </div>
            </template>
            <span>Exporter Liste des biens</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92" v-if="checkPermission('GBHTAB')">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                @click.prevent="handleModalBien('add', 'show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter bien</span>
          </v-tooltip>
        </div>
      </div>
      <div class="content-card-table custom-table-bien">
        <v-data-table
          v-model="selectedTable"
          :headers="fields"
          :items="biens"
          class="table-setting"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :disable-sort="true"
          :no-data-text="
            loadingBien
              ? 'Chargement en cours ...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!--NOM-->
          <template v-slot:[`item.nom`]="{ item }">
            <td class="custom-cell">{{ item.nom }}</td>
          </template>
          <!--TYPE-->
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type }}</td>
          </template>
          <!--ADRESSE-->
          <template v-slot:[`item.adresse`]="{ item }">
            <td class="custom-cell">{{ item.adresse }}</td>
          </template>
          <!--DESCRIPTION-->
          <template v-slot:[`item.description`]="{ item }">
            <td class="custom-cell">{{ item.description }}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="d-flex actions-style-bien">
                <div
                  class="bloc-icon-gestion mr-1"
                  v-if="checkPermission('GBHTMB')"
                  @click.prevent.stop="handleUpdate(item)"
                  title="Modifier bien"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </div>
                <div
                  v-if="item.blocked == false && checkPermission('GBHTBDB')"
                  class="bloc-icon-gestion  mr-1"
                  @click.prevent.stop="handleBlock(item)"
                  title="Bloquer bien"
                >
                  <font-awesome-icon icon="eye" />
                </div>
                <div
                  v-if="item.blocked && checkPermission('GBHTBDB')"
                  class="bloc-icon-gestion  mr-1"
                  @click.prevent.stop="handleBlock(item)"
                  title="Bloquer bien"
                >
                  <font-awesome-icon icon="eye-slash" />
                </div>
                <div
                  v-if="checkPermission('GBHTDB')"
                  class="bloc-icon-gestion mr-1"
                  @click.prevent.stop="duplicateBien(item)"
                  title="Dupliquer bien"
                >
                  <font-awesome-icon icon="copy" />
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL ADD BIEN-->
    <v-dialog
      v-model="addBien"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter bien </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalBien('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formAddBien">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="bienToAdd.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  :error-messages="errorMessagesNom"
                  required
                  class="msg-error"
                  validate-on-blur
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Adresse"
                  class="msg-error"
                  v-model="bienToAdd.adresse"
                  :error-messages="errorMessagesAdresse"
                  :persistent-placeholder="true"
                  :rules="rulesAdresse"
                  validate-on-blur
                  ref="adresse"
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="bienToAdd.description"
                  :error-messages="errorMessagesDescription"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  validate-on-blur
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="bienToAdd.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitAddBien"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalBien('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE BIEN-->
    <v-dialog
      v-model="updateModalBien"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="bienToUpdate && bienToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier Bien </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalBien('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formUpdate">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="bienToUpdate.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  required
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Adresse"
                  class="msg-error"
                  v-model="bienToUpdate.adresse"
                  :persistent-placeholder="true"
                  :rules="rulesAdresse"
                  ref="adresse"
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="bienToUpdate.description"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="bienToUpdate.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitUpdateBien"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalBien('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DUPLICATE BIEN-->
    <v-dialog
      v-model="duplicateModalBien"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="bienToUpdate && bienToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Dupliquer Bien </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalBien('duplicate', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formDuplicate">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="bienToUpdate.nom"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  required
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Adresse"
                  class="msg-error"
                  v-model="bienToUpdate.adresse"
                  :persistent-placeholder="true"
                  :rules="rulesAdresse"
                  ref="adresse"
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  class="msg-error"
                  v-model="bienToUpdate.description"
                  :persistent-placeholder="true"
                  ref="description"
                  :rules="rulesDescription"
                  outlined
                  rows="3"
                  color="#704ad1"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="bienToUpdate.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="submitDuplicateBien"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalBien('duplicate', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedTable: [],
      errorMessagesNom: [],
      errorMessagesAdresse: [],
      errorMessagesDescription: [],
      typeProduct: [
        { value: 'prestation', text: 'Prestation' },
        { value: 'marchandise', text: 'Marchandise' }
      ],
      page: 1,
      rulesNom: [v => !!v || 'Nom est obligatoire.'],
      rulesAdresse: [v => !!v || 'Adresse est obligatoire.'],
      rulesDescription: [v => !!v || 'Description est obligatoire.'],
      addBien: false,
      updateModalBien: false,
      duplicateModalBien: false,
      perPage: 50,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      fields: [
        { value: 'nom', text: 'Nom' },
        { value: 'type', text: 'type' },

        { value: 'adresse', text: 'Adresse' },
        { value: 'description', text: 'Description' },
        {
          value: 'actions',
          text: 'Actions'
        }
      ],
      error: null,
      bienToAdd: {
        nom: null,
        adresse: null,
        description: null,
        type: 'prestation'
      },
      loading: false,
      bienToUpdate: null,
      oldBienToUpdate: null,
      searchValue: null,
      loadingExport: false
    };
  },
  methods: {
    ...mapActions([
      'getAllBiens',
      'createBien',
      'updateBlockedBien',
      'updateBien',
      'resetState',
      'exportListBien'
    ]),
    async exportFiles() {
      this.loadingExport = true;
      const response = await this.exportListBien();
      if (response.success) {
        this.$alert('', response.msg, 'success');
      } else {
        this.$alert('', 'Une erreur est survenue', 'danger');
      }
      this.loadingExport = false;
    },
    handleModalBien(method, action) {
      if (method == 'add') {
        if (action == 'show') {
          this.addBien = true;
        } else {
          this.addBien = false;
          this.$refs.formAddBien.reset();
          this.resetModal();
        }
      }
      if (method == 'update') {
        if (action == 'show') {
          this.updateModalBien = true;
        } else {
          this.updateModalBien = false;
          this.resetModal();
          this.$refs.formUpdate.reset();
        }
      }
      if (method == 'duplicate') {
        if (action == 'show') {
          this.duplicateModalBien = true;
        } else {
          this.duplicateModalBien = false;
          this.resetModal();
          this.$refs.formDuplicate.reset();
        }
      }
    },
    async submitDuplicateBien() {
      this.loading = true;
      this.error = null;
      if (this.$refs.formDuplicate.validate()) {
        let bodyFormData = new FormData();
        bodyFormData.append('id', this.bienToUpdate.id);
        bodyFormData.append('nom', this.bienToUpdate.nom);
        bodyFormData.append('description', this.bienToUpdate.description);
        bodyFormData.append('adresse', this.bienToUpdate.adresse);
        bodyFormData.append('type', this.bienToUpdate.type);
        const response = await this.createBien(bodyFormData);
        if (response.success) {
          this.loading = false;
          this.handleModalBien('duplicate', 'hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      } else {
        this.loading = false;
      }
    },
    async submitUpdateBien() {
      this.loading = true;
      this.error = null;
      if (this.$refs.formUpdate.validate()) {
        let bodyFormData = new FormData();
        bodyFormData.append('id', this.bienToUpdate.id);
        bodyFormData.append('nom', this.bienToUpdate.nom);
        bodyFormData.append('description', this.bienToUpdate.description);
        bodyFormData.append('adresse', this.bienToUpdate.adresse);
        let payload = {
          oldBienToUpdate: this.oldBienToUpdate,
          bienToUpdate: bodyFormData
        };
        const response = await this.updateBien(payload);
        if (response.success) {
          this.loading = false;
          this.handleModalBien('update', 'hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      } else {
        this.loading = false;
      }
    },
    handleUpdate(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      this.handleModalBien('update', 'show');
    },
    duplicateBien(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      this.handleModalBien('duplicate', 'show');
    },
    async handleBlock(item) {
      this.bienToUpdate = { ...item };
      this.oldBienToUpdate = item;
      if (this.bienToUpdate.blocked == true) {
        let payload = {
          blocked: 0,
          id: this.bienToUpdate.id,
          item: this.oldBienToUpdate
        };
        const response = await this.updateBlockedBien(payload);
        if (response) {
          this.resetModal();
        }
      } else {
        let payload = {
          blocked: 1,
          id: this.bienToUpdate.id,
          item: this.oldBienToUpdate
        };
        const response = await this.updateBlockedBien(payload);
        if (response) {
          this.resetModal();
        }
      }
    },
    resetModal() {
      this.loading = false;
      this.bienToAdd = {
        nom: null,
        adresse: null,
        description: null,
        type: 'prestation'
      };
      this.bienToUpdate = {};
      this.oldBienToUpdate = null;
      this.resetState();
    },
    async submitAddBien() {
      this.loading = true;
      this.error = null;
      if (this.$refs.formAddBien.validate()) {
        let bodyFormData = new FormData();
        bodyFormData.append('nom', this.bienToAdd.nom);
        bodyFormData.append('adresse', this.bienToAdd.adresse);
        bodyFormData.append('description', this.bienToAdd.description);
        bodyFormData.append('type', this.bienToAdd.type);
        const response = await this.createBien(bodyFormData);
        if (response.success) {
          this.loading = false;
          this.handleModalBien('add', 'hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      } else {
        this.loading = false;
      }
    },
    async handleFilter() {
      await this.getAllBiens({
        per_page: this.perPage,
        page: this.page,
        nom: this.searchValue
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    }
  },
  computed: {
    ...mapGetters([
      'errorBiens',
      'loadingBien',
      'biens',
      'totalRowsBien',
      'checkPermission',
      'getShowNotifExportFile'
    ]),
    totalPages() {
      if (this.totalRowsBien) {
        return Math.ceil(this.totalRowsBien / this.perPage);
      }
      return this.totalRowsBien;
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  mounted() {
    this.handleFilter();
  }
};
</script>
<style scoped>
.actions-style-bien {
  position: relative;
  margin: auto;
  justify-content: center;
}
.custom-table-bien {
  height: calc(100vh - 274px) !important ;
}
</style>
